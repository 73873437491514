import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Sidebar from '../components/Sidebar/Sidebar'
import Seo from '../components/Seo/Seo'
import StairCalculator from '../components/StairCalculator/StairCalculator'
import IndexForm from '../components/IndexForm/IndexForm'
import GalleryGrid from '../components/GalleryGrid/GalleryGrid'
import {Container, Row, Col} from 'react-bootstrap'
import '../styles/templates/service-page-template.scss'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

export default ({data, location, pageContext}) => {
    const {
        breadcrumb: { crumbs },
      } = pageContext
    
    console.log(crumbs)
    if (crumbs.length < 5) {
        crumbs[crumbs.length - 3].crumbLabel = 'Услуги'
        crumbs[crumbs.length - 2].crumbLabel = data.contentfulServicePage.title
    }
    else {
        crumbs[crumbs.length - 4].crumbLabel = 'Услуги'
        crumbs[crumbs.length - 3].crumbLabel = data.contentfulServicePage.title
        switch (crumbs[crumbs.length - 2].pathname) {
            case '/services/public-places/restorants-bars-cafes':
                crumbs[crumbs.length - 2].crumbLabel = 'Рестораны, бары, кафе'
                break
            default :
                break
        }
    }


    const section = data.contentfulServicePageSection;
    const sectionNavigation = data.contentfulServicePage.pageContent;
    // const sectionSubNavigation = data.contentfulServicePageSection.subSection;
    const otherSections = data.allContentfulServicePage.nodes;
    const currentPage = data.contentfulServicePage.slug;
    const title = data.contentfulServicePageSection.title;
    const body = data.contentfulServicePageSection.body.childMarkdownRemark.html;
    const tags = data.contentfulServicePageSection.tags;
    const meta = data.contentfulServicePageSection.meta
    function stairPage() {
        return (
            <>
            <StairCalculator/> 
            <IndexForm isWhite={true} mail={data.contentfulCompanyContacts.mail}/>
            </>
        )
    }
    return (
        <Layout>
        <Seo 
          title = {meta ? meta.title : title} 
          description = {meta ? meta.description : data.contentfulServicePage.body}
          keywords = {meta ? meta.keywords : undefined}
          image = {meta ? (meta.image.file.url ?  meta.image.file.url : undefined) : undefined}
        />


        <Container className="content service-page" fluid={'xl'}>
        <Row className="section-with-sidebar">
            <Sidebar
            sectionNavigation={sectionNavigation}
            // sectionSubNavigation = {sectionSubNavigation}
            otherSections={otherSections}
            currentPage={currentPage}
            currentSection='services'
            />
            <Col className="page-with-sidebar" xs={12} md={8}  lg={9}>
                <Breadcrumb
                    crumbs={crumbs}
                    crumbLabel={title}
                />
                <section className='service-page-content'>
                    <h1 className='service-page--title'>{title}</h1>
                    <div
                        className='service-page--body service-page--content service-page--section'
                        dangerouslySetInnerHTML={{__html: body}}
                    />
                    {
                        tags ?
                        <GalleryGrid 
                            page='service'
                            items = {data.allContentfulProject.nodes}
                            activeTag = {tags}
                        /> :
                        ''
                    }
                </section>

            </Col>
        </Row>
        </Container>
        {crumbs[crumbs.length - 2].pathname === '/services/stairs' ? stairPage() : <IndexForm/>}
        {/* <StairCalculator/> */}
        
        </Layout>
    )
}

export const serviceSectionBySlug = graphql`
    query serviceSectionBySlug($slug: String!, $section: String!) {
        contentfulServicePageSection(slug: {eq: $slug}) {
            title
            slug
            body {
                childMarkdownRemark {
                    html
                }
            }
            tags {
                tag
            }
            meta {
                title
                description
                keywords
                image {
                    file {
                    url
                    }
                }
            }
        }
        contentfulCompanyContacts(title: {eq: "Дерево Желаний"}) {
       mail
    }

        contentfulServicePage(slug: {eq: $section}) {
            title
            slug
            pageContent {
                title
                slug
                subSection {
                title
                slug
            }
            }
        }

        allContentfulProject(sort: {fields: number, order: DESC}) {
            nodes {
                title
                number
                tags {
                    tag
                }
                heroImage {
                    title
                    fluid(maxWidth: 1000) {
                        ...GatsbyContentfulFluid
                    }
                    file {
                        url
                    }
                }
                images {
                    file {
                        url
                    }
                    title
                }
            }
        }
        allContentfulServicePage {
            nodes {
                title
                slug
            }
        }
    }
`